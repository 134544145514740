@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  src: url('../fonts/Rubik-Light.ttf');
}
@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('../fonts/Rubik-Regular.ttf');
}
@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url('../fonts/Rubik-Medium.ttf');
}
@font-face {
  font-family: 'Rubik';
  font-weight: 600;
  src: url('../fonts/Rubik-SemiBold.ttf');
}
@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  src: url('../fonts/Rubik-Bold.ttf');
}
body {
  color: #1a2c49 !important;
  font-family: 'Rubik', sans-serif;
}
.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  width: 100%;
  position: relative;
}
.embla-overflow-scroll {
  overflow: scroll;
  padding-left: 2px !important;
  padding-right: 2px !important;
  -ms-overflow-style: none; /* Add this line for IE and Edge */
  scrollbar-width: none; /* Add this line for Firefox */
}
.embla-overflow-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}
.embla-categories {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  position: relative;
}
.embla__viewport {
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  padding-left: var(--slide-spacing);
  position: relative;
}
.dot-span::before {
  content: '• ';
}
.dot-span {
  margin-left: 4px;
}
@keyframes moveLoadingBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.skeleton-shine {
  width: 100%;
  height: 100%;
  animation: shine-animation 1.5s infinite linear;
  background: linear-gradient(
    110deg,
    transparent 25%,
    rgba(255, 255, 255, 0.8) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
}

@keyframes shine-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.animate-loading-bar {
  animation: moveLoadingBar 1s ease-in-out infinite;
}
body.search-results-visible {
  overflow: hidden;
}
.grayscale {
  filter: grayscale(100%);
}
.gyg-widget-copy {
  position: relative;
  color: #1a2b49;
  border: 1px solid #dcdfe4;
  border-radius: 8px;
  padding: 20px 16px;
}
#getyourguide-widget > astro-island > div > section > footer {
  display: none;
}
.portableText p:last-child {
  margin-bottom: 0;
}

.goo {
  display: inline;
  box-decoration-break: clone;
  background: #fff;
  opacity: 0.9;
  padding: 0.3rem 0.4rem;
  filter: url('#goo');
}
.goo:focus {
  outline: 0;
}
#check-availability {
  scroll-margin-top: 0.75rem;
}
.CybotCookiebotDialogBodyButton {
  padding: 0px !important;
}
#CybotCookiebotDialogBodyContent {
  font-size: 0.4rem !important;
  line-height: 1rem !important;
  padding: 0 !important;
}
#CybotCookiebotDialogBodyContentTitle {
  margin-bottom: 0 !important;
}
#CybotCookiebotDialogBodyEdgeMoreDetails {
  margin: 0.2em 2em !important;
}
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
  transform: scale(0.7) !important;
}
.CybotCookiebotDialogBodyLevelButtonWrapper {
  margin-top: 0 !important;
  padding: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}
.CybotCookiebotDialogBodyLevelButtonLabel {
  margin-bottom: 0 !important;
}
.CybotCookiebotDialogBodyBottomWrapper {
  margin-top: 0 !important;
}
#CybotCookiebotDialogPoweredbyImage {
  transform: scale(1.7) !important;
}
.CybotCookiebotScrollContainer {
  min-height: 100px !important;
}
#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogPoweredByText {
  display: none !important;
}
#CybotCookiebotDialog {
  padding: 12px 24px !important;
}
#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  padding: 0.3em 0.8em !important;
}
.CybotCookiebotDialogContentWrapper {
  align-items: stretch !important;
}
#CybotCookiebotDialogFooter {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding: 0px !important;
}
.CybotCookiebotFader {
  display: none !important;
}
.CybotCookiebotDialogBodyBottomWrapper {
  border-top: none !important;
}
@media (max-width: 1280px) {
  #CybotCookiebotDialog {
    bottom: 0 !important;
    transform: translate(-50%, -7px) !important;
    padding: 6px !important;
    top: unset !important;
    height: fit-content !important;
  }
  #CybotCookiebotDialogBody {
    max-height: 100px !important;
    margin-bottom: 10px !important;
  }
  #CybotCookiebotDialogHeader {
    display: none !important;
  }
  #CybotCookiebotDialogBodyContent {
    font-size: 0.3rem !important;
    line-height: 1rem !important;
  }
}

@media (max-width: 600px) {
  .CybotCookiebotScrollContainer {
    min-height: 180px !important;
  }
}
.language-switcher::after {
  content: '';
  position: absolute;
  width: 100px;
  right: 0;
  top: 100%;
  height: 50px;
  pointer-events: none;
}
.language-switcher:hover::after {
  pointer-events: auto;
}
